.button {
  background: url('/arrow-right-circle.svg') no-repeat right 20px center, #fff;
  border-radius: var(--border-radius);
  display: block;
  padding: 20px 40px;
  width: 300px;
}

.footer {
  border-top: 1px solid var(--color-border);
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
  padding-top: 40px;
}
