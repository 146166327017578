.button {
  background: url('/arrow-right.svg') no-repeat right 20px center, var(--color-button-primary);
  border-radius: var(--border-radius);
  color: var(--color-text-unpainted);
  display: block;
  padding: 20px 40px;
  width: 300px;
}

.button:hover {
  opacity: 0.9;
}

@media (max-width: 640px) {
  .button {
    padding: 16px 24px;
    width: 100%;
  }
}
